<template>
  <v-card rounded class="transparent mx-auto mt-4 mb-10" flat>
    <InvitesDialog
      :show="showInvitesDialog"
      @keydown.esc="cancelInvites"
      @cancel="cancelInvites"
    />
    <BaseScreenHeader
      :title="title"
      screenInfo="FED-001"
      width="90%"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />
    <v-tabs
      active-class="charcoal"
      v-model="activeTab"
      center-active
      centered
      dark
      dense
      height="30"
      background-color="charcoal"
    >
      <v-tabs-slider color="paper" />
      <v-icon class="paper--text mr-2" @click="reloadDashboard">
          mdi-reload
        </v-icon>
      <v-tab :ripple="false" class="rounded-t-lg charcoal paper--text">
        Dashboard
      </v-tab>
      <v-tab :ripple="false" class="rounded-t-lg charcoal paper--text">
        Feed
      </v-tab>
      <v-tabs-items v-model="activeTab" class="charcoal">
        <v-tab-item class="charcoal">
          <MonthlyStats :reload="activeTab == 0" :forceReload="forceReload" class="mt-2" />
        </v-tab-item>
        <v-tab-item class="charcoal">
          <BaseLoading
            class="mt-10 mb-5"
            :loaded="loaded"
            label="Loading Feed..."
          />
          <v-sheet class="transparent charcoalTile mt-1 pt-2 px-2" rounded>
            <v-row no-gutters>
              <v-col cols="6" :class="textSizeXSmall + ' py-0 ml-0'"> </v-col>
              <v-col cols="6" align="right">
                <v-spacer />
                <template>
                  <a
                    class="progressActive--text text-h4"
                    title="Invite Your Friends!"
                    @click="showInvites"
                  >
                    INVITE
                    <v-icon large class="paper--text mt-n2 ml-n1 mr-n1">
                      mdi-account-multiple-plus-outline
                    </v-icon>
                  </a>
                </template>
                <template>
                  <v-card-actions
                    :class="textSizeXSmall + ' caption paper--text mt-n2 pa-0 '"
                  >
                    <v-spacer />
                    {{ invites.length }} invites available
                  </v-card-actions>
                </template>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="charcoal mr-n1 ml-n2">
            <v-timeline
              :key="switchNum"
              dense
              :class="isPhone ? 'ml-n5 pt-2' : ''"
            >
              <v-timeline-item
                v-if="news.length > 0 && loaded"
                color="progressActive"
                fill-dot
                class=""
              >
                <template v-slot:icon>
                  <v-icon size="40" color="charcoal">
                    mdi-information
                  </v-icon>
                </template>
                <v-carousel
                  class="ml-n6 pa-0"
                  v-model="newsIndex"
                  :touchless="false"
                  interval="1500"
                  :show-arrows="false"
                  hide-delimiter-background
                  delimiter-icon="mdi-circle"
                  height="100%"
                  @change="flips++"
                  v-touch:swipe.left="newsLeft"
                  v-touch:swipe.right="newsRight"
                >
                  <v-carousel-item
                    rounded
                    color="charcoal"
                    class="pa-0 ma-0"
                    v-for="(item, i) in news"
                    :key="i"
                  >
                    <v-card
                      rounded
                      flat
                      class="pa-2 pb-0 mb-10 transparent charcoalTile"
                    >
                      <v-card-actions
                        :class="textSizeXSmall + ' pa-0 silver--text'"
                      >
                        {{ prettyDateTime(item.created_local) }}
                        <v-spacer />
                      </v-card-actions>
                      <v-card-text class="pa-0">
                        <v-card-text
                          :class="textSizeSmall + ' pa-0 my-1 gold--text'"
                        >
                          {{ item.headline }}
                        </v-card-text>
                        <div v-html="item.details" class="paper--text" />
                      </v-card-text>
                      <v-card-actions class="pa-0 mb-2">
                        <span class="link">
                          <v-icon
                            :color="item.liked == 'Yes' ? 'paper' : 'silver'"
                            large
                            class="mt-n6"
                            @click="likeNews(item)"
                          >
                            mdi-thumb-up-outline
                          </v-icon>
                          <span :class="textSize + ' silver--text ml-n1'">
                            {{ item.up }}
                          </span>
                        </span>
                        <v-spacer v-if="isPhone" />
                      </v-card-actions>
                    </v-card>
                  </v-carousel-item>
                </v-carousel>
              </v-timeline-item>
              <template v-if="feedList?.length > 0 && loaded">
                <v-timeline-item
                  class=""
                  :color="
                    item.code == 'EXERCISE_NEW_PR' ? 'paper' : 'progressActive'
                  "
                  fill-dot
                  v-for="item in feedList"
                  :key="item.id"
                >
                  <template v-slot:icon>
                    <v-icon
                      large
                      v-if="item.code == 'EXERCISE_NEW_PR'"
                      color="charcoal"
                      @click="showExerciseInfoDialog(item.data.exerciseid)"
                    >
                      mdi-trophy-award
                    </v-icon>
                    <v-icon
                      v-else-if="item.code == 'WORKOUT_FINISHED_LIFTING'"
                      color="charcoal"
                      class="link"
                      @click="showWorkout(item)"
                    >
                      mdi-weight-lifter
                    </v-icon>
                    <v-icon
                      v-else-if="
                        item.code.match(/ACTIVITY.*/) ||
                          item.code.match(/WELLNESS.*/)
                      "
                      color="charcoal"
                      class="link"
                      @click="showWorkout(item)"
                    >
                      {{ item.data.icon }}
                    </v-icon>
                  </template>
                  <template v-slot:opposite>
                    <span class="caption silver--text">
                      {{ prettyDate(item.created_local) }}
                    </span>
                  </template>
                  <div class="ml-n6">
                    <v-card
                      rounded
                      flat
                      class="pa-2 pt-1 charcoal charcoalTileMenu"
                    >
                      <v-card-actions
                        :class="textSizeXSmall + ' py-0 px-0 silver--text'"
                      >
                        {{ prettyDateTime(item.created_local) }}
                        <v-spacer />
                        <v-icon
                          v-if="canEdit(item)"
                          color="silver"
                          class="mr-n2"
                          @click="deleteEvent(item)"
                        >
                          mdi-trash-can
                        </v-icon>
                      </v-card-actions>
                      <v-card-text class="pa-0">
                        <BaseUserProfile :item="item" />
                        <span v-html="item.details" class="paper--text" />
                      </v-card-text>
                      <v-card-actions class="pa-0 mt-1">
                        <span :class="textSize + ' silver--text ml-1'">
                          <span class="link" @click="like(item)">
                            <v-icon
                              :color="item.liked == 'Yes' ? 'paper' : 'silver'"
                              large
                              class="mt-n2"
                            >
                              mdi-thumb-up-outline
                            </v-icon>
                            <span class="ml-n1"> {{ item.up }}</span>
                          </span>
                        </span>
                        <span class="link" @click="showComments(item, 'event')">
                          <v-icon color="silver" class="ml-2 mt-n2" large>
                            mdi-comment-text-outline
                          </v-icon>
                          <span :class="textSize + ' silver--text ml-n1'">
                            {{ item.comments }}
                          </span>
                        </span>
                        <v-spacer v-if="isPhone" />
                        <BaseActionButton
                          v-if="item.code == 'WORKOUT_FINISHED_LIFTING'"
                          icon="mdi-link-variant"
                          plain
                          small
                          color="progressActive"
                          label="Workout"
                          title="View Workout"
                          class="mr-n2"
                          @clickedThis="showWorkout(item)"
                        />
                        <BaseActionButton
                          v-if="item.code == 'EXERCISE_NEW_PR'"
                          icon="mdi-link-variant"
                          plain
                          small
                          color="progressActive"
                          label="Exercise"
                          title="View Exercise"
                          class="mr-n2"
                          @clickedThis="
                            showExerciseInfoDialog(item.data.exerciseid)
                          "
                        />
                      </v-card-actions>
                      <template v-if="item.showComments">
                        <v-card-text class="silver--text pa-0">
                          <tiptap-vuetify
                            dark
                            v-model="item.newComment"
                            :extensions="extensions"
                            placeholder="Enter new comment"
                            :toolbar-attributes="{
                              color: 'charcoal',
                              dark: true
                            }"
                            :card-props="{
                              flat: true,
                              class: 'charcoal lighten-1 silver--text'
                            }"
                          />
                        </v-card-text>
                        <v-card-actions
                          v-if="checkComment(item.newComment)"
                          class="pa-0 mr-n2"
                        >
                          <v-spacer />
                          <BaseActionButton
                            color="paper"
                            icon=""
                            plain
                            label="+ Add Comment"
                            @clickedThis="addComment(item, 'event')"
                          />
                        </v-card-actions>
                        <div class="mt-2">
                          <v-card
                            class="mt-1 charcoal"
                            tile
                            flat
                            v-for="(comment, index) in item.loadedComments"
                            :key="index"
                          >
                            <v-card-actions class="pa-0">
                              <span class="caption silver--text">
                                <BaseUserProfile :item="comment" />
                                on {{ prettyDateTime(comment.created_local) }}
                              </span>
                              <v-spacer />
                            </v-card-actions>
                            <v-card-text
                              v-html="comment.comment"
                              class="pa-0 ml-2 charcoal silver--text"
                            />
                            <v-card-actions class="pa-0 mt-n4 ml-2">
                              <span @click="likeComment(comment)" class="link">
                                <v-icon
                                  small
                                  :color="
                                    comment.liked == 'Yes' ? 'paper' : 'silver'
                                  "
                                  class="mt-n2"
                                >
                                  mdi-thumb-up-outline
                                </v-icon>
                                <span
                                  :class="textSizeSmall + ' silver--text ml-n1'"
                                >
                                  {{ comment.likes }}
                                </span>
                              </span>
                              <span
                                @click="replyToComment(comment)"
                                class="link ml-2"
                              >
                                <v-icon small color="silver" class="mt-n1">
                                  mdi-comment-plus-outline
                                </v-icon>
                                <span
                                  :class="
                                    textSizeXSmall + ' silver--text ml-n1'
                                  "
                                  >Reply</span
                                >
                              </span>
                              <v-spacer />
                              <v-icon
                                small
                                v-if="canEdit(comment)"
                                color="silver"
                                @click="deleteComment(item, comment)"
                              >
                                mdi-trash-can
                              </v-icon>
                            </v-card-actions>
                            <template v-if="comment.showReply">
                              <tiptap-vuetify
                                class="caption ml-4"
                                dark
                                v-model="comment.reply"
                                :extensions="extensions"
                                placeholder="Reply to comment"
                                :toolbar-attributes="{
                                  color: 'charcoal',
                                  dark: true
                                }"
                                :card-props="{
                                  flat: true,
                                  class: 'charcoal lighten-1 silver--text'
                                }"
                              />
                              <v-card-actions
                                v-if="checkComment(comment.reply)"
                                class="pa-0 mr-n2"
                              >
                                <v-spacer />
                                <BaseActionButton
                                  color="paper"
                                  icon=""
                                  plain
                                  label="+ Reply"
                                  @clickedThis="reply(comment, item)"
                                />
                              </v-card-actions>
                            </template>
                            <v-card
                              class="mt-n ml-4 charcoal"
                              tile
                              flat
                              v-for="(reply, index) in comment.replies"
                              :key="index"
                            >
                              <v-card-actions class="pa-0 silver--text">
                                <v-icon x-small color="silver">
                                  mdi-subdirectory-arrow-right
                                </v-icon>
                                <a
                                  class="caption mr-1 progressActive--text"
                                  :href="'/profile/' + reply.profileid"
                                  :title="reply.display_name"
                                >
                                  {{ reply.display_name }}
                                </a>
                                <span class="caption silver--text">
                                  on {{ prettyDateTime(reply.created_local) }}
                                </span>
                                <v-spacer />
                              </v-card-actions>
                              <v-card-text
                                v-html="reply.comment"
                                class="pa-0 ml-2 charcoal silver--text"
                              />
                              <v-card-actions class="pa-0 mt-n4 ml-2">
                                <span @click="likeComment(reply)">
                                  <v-icon
                                    small
                                    :color="
                                      reply.liked == 'Yes' ? 'paper' : 'silver'
                                    "
                                  >
                                    mdi-thumb-up-outline
                                  </v-icon>
                                  <span
                                    :class="
                                      textSizeSmall + ' silver--text ml-0'
                                    "
                                  >
                                    {{ reply.likes }}
                                  </span>
                                </span>
                                <v-spacer />
                                <v-icon
                                  small
                                  v-if="canEdit(reply)"
                                  color="silver"
                                  @click="deleteReply(reply, comment, item)"
                                >
                                  mdi-trash-can
                                </v-icon>
                              </v-card-actions>
                            </v-card>
                            <v-divider class="charcoal lighten-1 mt-2" />
                          </v-card>
                        </div>
                      </template>
                    </v-card>
                  </div>
                </v-timeline-item>
              </template>
              <BaseLoading
                class="mt-2 mb-5"
                :loaded="!loadingMore"
                label="Loading More..."
              />
              <template v-if="feedList?.length == 0 && loaded">
                <v-timeline-item class="ml-n1" color="charcoal">
                  <v-card-text class="silver--text pa-0">
                    Your feed is currently empty. Don't worry, it will fill up
                    in time.
                  </v-card-text>
                </v-timeline-item>
              </template>
            </v-timeline>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <WorkoutTile
      v-if="selectedWorkout != null"
      :workoutid="selectedWorkout"
      :showWorkoutInfo="showWorkoutDialog"
      @cancel="cancel"
    />
    <v-dialog
      v-model="showExerciseInfo"
      width="1024"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <ExerciseInfo
        :exerciseid="exerciseid"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
const MonthlyStats = () =>
  import(/* webpackPrefetch: true */ '@/components/blocks/MonthlyStats.vue')
import {
  TiptapVuetify,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak
} from 'tiptap-vuetify'

const WorkoutTile = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/WorkoutTile.vue')
const InvitesDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/InvitesDialog.vue')

export default {
  components: {
    WorkoutTile,
    InvitesDialog,
    TiptapVuetify,
    ExerciseInfo,
    MonthlyStats
  },
  name: 'DefaultFeed',
  mixins: [validationMixin, util],
  data() {
    return {
      forceReload : 0,
      activeTab: 0,
      newsIndex: 0,
      switchNum: 0,
      loaded: false,
      loadingMore: false,
      isActive: false,
      showWorkoutDialog: false,
      showDeleteDialog: false,
      showStats: false,
      showInvitesDialog: false,
      showExerciseInfo: false,
      invites: [],
      volumeStats: {},
      isUserScrolling: false,
      isNow: true,
      selectedWorkout: null,
      exerciseid: null,
      metric: 0,
      imperial: 0,
      flips: 0,
      search: '',
      selectedMeasure: [],
      measureList: [],
      feedList: null,
      news: [],
      profile: null,
      datetime: null,
      debouncedScroll: null,
      debounce: null,
      pagesize: 20,
      currentPage: 0,
      bottom: false,
      reload: false,
      hasIron: true,
      hasMovement: true,
      profileLoaded: false,
      moving: null,
      timedout: false,
      api: null,
      extensions: [
        Paragraph,
        Bold,
        Italic,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  beforeMount() {
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: this.reqTimeout
    })
    this.loadUserStats()
    this.loadInvites()
    this.loadNews()
  },
  mounted() {
    this.loadFeed()
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
    window.addEventListener('message', this.receiveMessage)

    this.loadExercises()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
    window.removeEventListener('message', this.receiveMessage)
  },
  watch: {
    bottom(bottom) {
      if (bottom && !this.reload && this.loaded) {
        this.currentPage++
        this.loadFeed()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.feedList ? 0 : this.feedList.length
    },
    title() {
      return 'BFitr Home'
    },
    ...appConfig
  },
  methods: {
    reloadDashboard(){
      this. refreshPage() 
      this.forceReload ++;
    },  
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    newsLeft() {
      if (this.newsIndex <= this.news.length) this.newsIndex++
    },
    newsRight() {
      if (this.newsIndex >= 1) this.newsIndex--
    },
    showExerciseInfoDialog(exerciseid) {
      this.exerciseid = exerciseid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    checkComment(newComment) {
      if (!newComment) return false
      let noHTML = newComment.replace(/<[^>]*>?/gm, '')
      return noHTML.length >= 2
    },
    refreshPage() {
      this.currentPage = 0
      this.reload = true
      this.loadUserStats()
      this.loadInvites()
      this.loadNews()
      this.loadFeed()
    },
    replyToComment(comment) {
      comment.showReply = !comment.showReply
    },
    showComments(item, type) {
      item.showComments = !item.showComments
      if (item.showComments) this.loadComments(item, type)
    },
    hideComments(item) {
      item.showComments = false
    },
    showInvites() {
      this.showInvitesDialog = true
    },
    cancelInvites() {
      this.showInvitesDialog = false
    },

    loadUserStats() {
      return this.api
        .get('/stats/volume30')
        .then(response => {
          if (response.status === 200) {
            this.timedout = false
            const { volume, reps, moving } = response.data.data
            this.volumeStats = volume
            this.reps = reps
            this.moving = moving
            this.hasIron = volume?.volume_metric > 0
            this.hasMovement = moving && moving?.distance_metric != null
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
          }
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED') {
            this.timedout = true
          } else {
            null
          }
        })
    },
    loadComments(item, type) {
      let pagination = '/' + item.currentPage + '/' + item.pagesize
      return axios
        .get(this.baseURL + '/comments/' + type + '/' + item.id + pagination, {
          timeout: this.reqTimeout
        })
        .then(response => {
          if (response.status == 200) {
            this.timedout = false
            for (let element of response.data.data) {
              element.showReply = false
              element.reply = ''
              element.replies = JSON.parse(element.replies)
            }
            item.loadedComments = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
        .catch(() => {
          this.timedout = true
        })
    },
    loadInvites() {
      return axios.get(this.baseURL + '/invites', {}).then(response => {
        if (response.status == 200) {
          this.invites = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    loadNews() {
      return this.api
        .get('/feed/news/active')
        .then(response => {
          if (response.status === 200) {
            this.news = response.data.data
            this.timedout = false
            localStorage.setItem('news', JSON.stringify(this.news.slice(0, 5)))
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
          }
        })
        .catch(() => {
          let localNews = localStorage.getItem('news')
          this.timedout = true
          // If there is localNews, only then assign it to this.news
          if (localNews) {
            this.news = JSON.parse(localNews)
          }
        })
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    showWorkout(value) {
      this.selectedWorkout = value.data.workoutid
      this.showWorkoutDialog = true
    },
    showProfile(profileid) {
      this.$router.push('/profile/' + profileid)
    },
    cancel() {
      this.selectedWorkout = null
      this.showWorkoutDialog = false
    },
    previewProfile(item) {
      this.profileLoaded = false
      return axios
        .get(this.baseURL + '/user/public/profile/' + item.data.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            this.profileLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    likeComment(comment) {
      if (comment.liked != 'Yes') {
        return axios
          .post(this.baseURL + '/comment/like', {
            commentid: comment.id,
            now: this.now()
          })
          .then(response => {
            if (response.status == 200) {
              comment.likes++
              comment.liked = 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      } else {
        return axios
          .post(this.baseURL + '/comment/unlike', { commentid: comment.id })
          .then(response => {
            if (response.status == 200) {
              comment.likes--
              comment.liked = 'No'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },

    likeNews(news) {
      return axios
        .post(this.baseURL + '/feed/news/like', {
          newsid: news.id,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let alreadyLiked = response.data.data

            if (alreadyLiked == 'false') {
              news.up++
              news.liked = 'Yes'
            } else {
              news.up--
              news.liked = 'No'
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    reply(comment, item) {
      return axios
        .post(this.baseURL + '/comment/reply', {
          comment: comment,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let reply = JSON.parse(response.data.data)
            if (comment.replies != null) comment.replies.unshift(reply)
            else {
              comment.replies = []
              comment.replies[0] = reply
            }
            item.comments++
            this.toast('Reply Added!')
            comment.reply = ''
            comment.showReply = false
            comment.num_replies++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addComment(item, type) {
      return axios
        .post(this.baseURL + '/comment', {
          item: item,
          type: type,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let comment = JSON.parse(response.data.data)
            item.loadedComments.unshift(comment)
            this.toast('Comment Added!')
            item.newComment = ''
            item.comments++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteEvent(event) {
      return axios
        .delete(this.baseURL + '/event/' + event.id)
        .then(response => {
          if (response.status == 200) {
            this.feedList = this.feedList.filter(el => {
              return el.id != event.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteReply(reply, comment, item) {
      return axios
        .delete(this.baseURL + '/comment/reply/' + reply.id)
        .then(response => {
          if (response.status == 200) {
            comment.num_replies--
            item.comments--
            comment.replies = comment.replies.filter(el => {
              return el.id != reply.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteComment(item, comment) {
      return axios
        .delete(this.baseURL + '/comment/event/' + comment.id + '/' + item.id)
        .then(response => {
          if (response.status == 200) {
            item.loadedComments = item.loadedComments.filter(el => {
              return el.id != comment.id
            })
            item.comments--
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    like(event) {
      if (event.liked == 'Yes') {
        event.liked = 'No'
        event.up--
      } else {
        event.liked = 'Yes'
        event.up++
      }
      return axios
        .post(this.baseURL + '/event/like', { event: event, now: this.now() })
        .then(response => {
          if (response.status == 200) {
            null
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadExercises() {
      let localExercises = localStorage.getItem('exercises')
      if (localExercises == null) {
        return axios
          .get(this.baseURL + '/exercises/favoritesfirst', {})
          .then(response => {
            if (response.status == 200) {
              localExercises = response.data.data
              localStorage.setItem('exercises', JSON.stringify(localExercises))
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadFeed() {
      if (this.bottom) {
        this.loadingMore = true
        this.loaded = true
      } else {
        this.loadingMore = false
        this.loaded = false
      }
      return this.api
        .get(`/feed/scroll/${this.currentPage}/${this.pagesize}`)
        .then(response => {
          if (response.status === 200) {
            let responseData = response.data.data
            for (let element of responseData) {
              element.data = JSON.parse(element.data)
              element.showComments = false
              element.pagesize = this.pagesize
              element.currentPage = 0
              element.newComment = ''
              element.loadedComments = []
              element.preview_profile = false
            }
            if (this.reload || this.feedList == null)
              this.feedList = responseData
            else this.feedList = this.feedList.concat(responseData)
            this.localUpdate('feed', this.feedList.slice(0, 30))
            this.reload = false
            this.timedout = false
            this.loaded = true
            this.loadingMore = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
          }
        })
        .catch(() => {
          this.loaded = true
          this.timedout = true

          let localFeed = localStorage.getItem('feed')
          if (localFeed) {
            this.feedList = JSON.parse(localFeed)
          }
        })
    }
  }
}
</script>
<style scoped>
/* Override the before pseudo-element that causes brightness */
.v-tabs .v-tab--active::before {
  opacity: 0 !important; /* Remove any transparency */
}
</style>
